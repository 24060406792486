<template>
    <div>請先<a class="login" @click="$login()">登入</a></div>
</template>

<script>
export default {
    name: 'Login'
}
</script>

<style lang="scss" scoped>
.login {
    color: $primary-blue;
    cursor: pointer;
}

::v-global(.section-icon) {
    display: none;
}

::v-global(.container) {
    justify-content: flex-start;

    @media screen and (min-width: 0px) and (max-width: 1280px) {
        margin: 40px Max(15px, env(safe-area-inset-left, 15px));
    }
}
</style>
